<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Berita </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateBerita">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_berita"
                  label="Judul Berita"
                  placeholder="Input Judul Berita"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="isi_berita"
                  label="Isi Berita"
                  placeholder="Input Isi Berita"
                />  
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Kategori</label>
                  <select class="form-control" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kategori --</option>
                    <option v-for="r in kategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol> -->                     
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Berita</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto1">
                  </div>
                </div>  
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="caption_gambar_berita"
                  label="Caption Gambar Berita"
                  placeholder="Input Caption Gambar Berita"
                />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="penulis"
                  label="Penulis"
                  placeholder="Input Penulis"
                />
              </CCol>
            </CRow>        
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/berita">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>   
</template>
<script>

import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      judul_berita : "",
      isi_berita : "",
      caption_gambar_berita : "",
      penulis : "",
      gambar_berita_text : "",
      gambar_berita : "",
      foto1 : "",
      placeholder : "Pilih File",
      // kategori : [],
      // subkategori : [],
      selected: [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  // this.loadData();
  this.showBerita();
  },
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"api/public/kategoris/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
    //           this.kategori=response.data;
    //     });
    // },

    onFileChange(event) {
      var fileData = event.target.files[0];
      this.gambar_berita_text = fileData.name;
      this.gambar_berita = fileData;
      this.placeholder = fileData.name;
    },

    updateBerita: function(event){

      const berita = { 
                      judul_berita:this.judul_berita,
                      isi_berita: this.isi_berita, 
                      caption_gambar_berita: this.caption_gambar_berita,
                      penulis: this.penulis,
                      foto1: this.foto1,
                      id_berita: this.$route.params.id_berita
                    };

        const formData = new FormData()
        formData.append('judul_berita', this.judul_berita)
        formData.append('isi_berita', this.isi_berita)
        formData.append('caption_gambar_berita', this.caption_gambar_berita)
        formData.append('penulis', this.penulis)
        formData.append('foto1', this.foto1)

        if (this.gambar_berita_text) {
          formData.append('gambar_berita', this.gambar_berita, this.gambar_berita.name) 
          formData.append('gambar_berita_text', this.gambar_berita.name)
        }

      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"berita/"+this.$route.params.id_berita, formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/berita');
            }
            else{
              this.modalError = true;
            }
            
        })
    },
    
    showBerita: function(event){
      // Simple POST request with a JSON body using axios
      const berita = { 
                      judul_berita:this.judul_berita,
                      isi_berita: this.isi_berita, 
                      caption_gambar_berita: this.caption_gambar_berita,
                      penulis: this.penulis,
                      gambar_berita: this.gambar_berita
                    };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"berita/"+this.$route.params.id_berita)
        .then((response) => {
            
              this.isi_berita=response.data.isi_berita;
              this.judul_berita=response.data.judul_berita;
              this.caption_gambar_berita=response.data.caption_gambar_berita;
              this.penulis=response.data.penulis;
              this.placeholder=response.data.gambar_berita;
              this.foto1=response.data.gambar_berita;            
        })
    }
  }
}
</script>